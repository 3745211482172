@font-face {
  font-family: 'Poppins';
  src: local('sans-serif'),
    url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

* {
  font-family: 'Poppins', sans-serif;
}

:root {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #444444;
}

a {
  color: #444444;
  text-decoration: none;
}

a:hover {
  color: #444444;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

.ui.dimmer {
  background-color: rgba(40, 101, 141, 0.95);
}

.ui.basic.modal > .header {
  color: #f4873e !important;
}

.ui.fullscreen.modal,
.ui.fullscreen.scrolling.modal {
  left: auto !important;
}

.ui.fullscreen.modal,
.ui.fullscreen.scrolling.modal {
  left: auto !important;
}

.modal {
  height: auto !important;
  left: auto !important;
  top: auto !important;
  overflow: visible !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message,
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 80px !important;
}

.ant-input,
.ant-picker,
.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0.5rem !important;
}

.ant-form-item .ant-input-textarea-show-count::after {
  bottom: -28px !important;
}

.ant-input[disabled],
.ant-select-disabled.ant-select .ant-select-selector {
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: #f9f9f9 !important;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #2487ce;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #3e9bdd;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: '';
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #1f7a69;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

  .container-vinhedo {
    width: 100% !important;
    max-width: none;
    clear: none;
    float: left;
    margin: 15px 0;
    padding: 0 20px;
  }

  .container-vinhedo p {
    text-align: center;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  background: #6c7888;
  z-index: 997;
  padding: 15px 0;
}

#header.header-scrolled {
  border-color: #fff;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 0.5px;
  font-family: 'Poppins', sans-serif;
}

#header .logo a {
  color: #34cbaf;
}

#header .logo img {
  max-height: 40px;
}

@media (max-width: 992px) {
  #header .logo {
    font-size: 28px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar .getstarted {
  background: #1f7a69;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover {
  color: #fff;
  background: #3194db;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #2487ce;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(10, 38, 58, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #124265;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #f26201;
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #1f7a69;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.category-listing .single-listing .input-form input {
  width: 100%;
  height: 45px;
  background: #fff;
  color: #777777;
  font-size: 18px;
  font-weight: 400;
  padding: 9px 33px 9px 18px;
  border: 1px solid #ededed;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
}

.category-listing .single-listing .select-job-items1 .nice-select,
.category-listing .single-listing .select-job-items2 .nice-select {
  width: 100%;
  height: 45px;
  background: #fff;
  border-radius: 5px;
  padding: 11px 19px 11px 18px;
  color: #616875;
  line-height: 20px;
  border: 1px solid #ededed;
  margin-bottom: 20px;
}

@media (max-width: 575px) {
  .category-listing .single-listing .select-job-items1 .nice-select,
  .category-listing .single-listing .select-job-items2 .nice-select {
    padding-left: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .category-listing .single-listing .select-job-items1 .nice-select,
  .category-listing .single-listing .select-job-items2 .nice-select {
    padding-left: 25px;
  }
}

.category-listing .single-listing .select-job-items1 .nice-select .list,
.category-listing .single-listing .select-job-items2 .nice-select .list {
  width: 100%;
}

.category-listing .single-listing .select-job-items1 .nice-select.open .list,
.category-listing .single-listing .select-job-items2 .nice-select.open .list {
  width: 100%;
  border-radius: 0;
  border: 0;
}

.category-listing .single-listing .select-job-items1 .nice-select::after,
.category-listing .single-listing .select-job-items2 .nice-select::after {
  border-bottom: 1px solid #a9b6cd;
  border-right: 1px solid #a9b6cd;
  height: 12px;
  width: 12px;
  margin-top: -9px;
  right: 29px;
}

.category-listing .single-listing .select-Categories .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #666666;
  font-size: 16px;
}

@media (max-width: 575px) {
  .category-listing .single-listing .select-Categories .container {
    font-size: 14px;
  }
}

.category-listing .single-listing .select-Categories .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.category-listing .single-listing .select-Categories .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #ddd;
}

.category-listing
  .single-listing
  .select-Categories
  .container
  input:checked
  ~ .checkmark {
  background-color: #ff4357;
  border: 2px solid transparent;
  border-radius: 5px;
}

.category-listing .single-listing .select-Categories .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.listing-details-area .single-listing .list-caption {
  border: 1px solid #e1ebf7;
  padding: 31px 24px 18px 24px;
  border-top: 0;
  position: relative;
  z-index: 0;
}

.single-listing .list-caption {
  background-color: #fff;
  padding: 15px 24px 18px 24px;
  border-top: 0;
  position: relative;
  z-index: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 20px;
  min-height: 245px;
  /* max-height: 245px; */
}

.immobile-icons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@media (max-width: 575px) {
  .immobile-icons-container {
    margin: 15px 0;
    padding-left: 4px;
  }
}

.single-listing .list-caption-popular:hover {
  border: 1px solid #ff3d1c;
  border-top: 0;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
}

.listing-details-area .single-listing .list-caption .listing-span {
  color: #fff;
  position: absolute;
  top: -18px;
  right: 7px;
  font-size: 16px;
  font-weight: 500;
  width: 96px;
  height: 35px;
  background: #ff3d1c;
  border-radius: 12px;
  text-align: center;
  line-height: 35px;
}

.list-caption {
  color: #002f51;
  font-size: 12px;
}

.listing-price {
  font-size: 25px;
  font-weight: 700;
}

.title-immobile,
.address-immobile {
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3em;
  width: 360px;
}

.address-immobile {
  color: #002f51;
  font-size: 12px;
  font-weight: normal;
  height: auto;
}

@media (max-width: 1400px) {
  .title-immobile,
  .address-immobile {
    width: 300px;
  }
}

@media (max-width: 1200px) {
  .title-immobile,
  .address-immobile {
    width: 250px;
  }
}

@media (max-width: 1000px) {
  .title-immobile,
  .address-immobile {
    width: 170px;
  }
}

@media (max-width: 770px) {
  .title-immobile,
  .address-immobile {
    width: calc(100% - 10px);
  }
}

.lazyload-wrapper img {
  /* width: auto !important; */
  height: 100% !important;
  min-height: 408px !important;
}

.single-listing .list-footer {
  border-top: 1px solid #e1ebf7;
  margin-top: 20px;
  padding-top: 20px;
  font-weight: 700;
  /* font-size: 20px; */
}

.single-listing .list-caption .listing-span {
  color: #fff;
  position: absolute;
  top: -18px;
  right: 7px;
  font-size: 16px;
  font-weight: 500;
  width: 96px;
  height: 35px;
  background: #3193d1;
  border-radius: 12px;
  text-align: center;
  line-height: 35px;
}

.single-listing .list-caption-popular .listing-span {
  color: #fff;
  position: absolute;
  top: -18px;
  right: 7px;
  font-size: 16px;
  font-weight: 500;
  width: 196px;
  height: 35px;
  background: #3193d1;
  border-radius: 12px;
  text-align: center;
  line-height: 35px;
}

.single-listing .list-caption-popular p {
  padding-top: 20px;
  font-size: 20px;
  font-weight: 900;
}

.listing-details-area .single-listing .list-caption h3 {
  padding-right: 48px;
  margin-bottom: 17px;
}

.single-listing .list-caption h3 {
  padding-right: 48px;
  margin-bottom: 17px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .listing-details-area .single-listing .list-caption h3 {
    padding-right: 0px;
  }

  .single-listing .list-caption h3 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .listing-details-area .single-listing .list-caption h3 {
    padding-right: 0px;
  }

  .single-listing .list-caption h3 {
    padding-right: 0px;
  }
}

@media (max-width: 575px) {
  .listing-details-area .single-listing .list-caption h3 {
    padding-right: 0px;
  }

  .single-listing .list-caption h3 {
    padding-right: 0px;
  }
}

.listing-details-area .single-listing .list-caption h3 a {
  color: #1c1930;
  font-weight: 700;
  font-size: 25px;
}

.single-listing .list-caption h3 a {
  color: #1c1930;
  font-weight: 700;
  font-size: 25px;
}

.listing-details-area .single-listing .list-caption p {
  color: #002f51;
}

.single-listing .list-caption p {
  color: #002f51;
  font-size: 12px;
}

.listing-details-area .single-listing .list-caption .listing-span {
  color: #fff;
  position: absolute;
  top: -18px;
  right: 7px;
  font-size: 16px;
  font-weight: 500;
  width: 96px;
  height: 35px;
  background: #ff3d1c;
  border-radius: 12px;
  text-align: center;
  line-height: 35px;
}

.lazyload-wrapper img {
  /* width: auto !important; */
  height: 100% !important;
  min-height: 408px !important;
}

.single-listing {
  border: 1px solid rgb(203, 214, 220);
  z-index: 0;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;

  border-radius: 12px;
  box-shadow: 0px 0px 5px 0px rgba(42, 34, 123, 0.3);
  /* height: auto; */
  padding-bottom: 20px;
}

@media (max-width: 1400px) {
  .single-listing {
    min-height: 560px;
  }
}

@media (max-width: 1200px) {
  .single-listing {
    min-height: 580px;
  }
}

@media (max-width: 1000px) {
  .single-listing {
    min-height: 620px;
  }
}

@media (max-width: 770px) {
  .single-listing {
    min-height: 580px;
  }
}

.single-listing:hover {
  box-shadow: 0px 0px 5px 5px rgba(42, 34, 123, 0.3);
}

.single-listing .list-footer {
  border-top: 1px solid #e1ebf7;
  margin-top: 20px;
  padding-top: 20px;
  font-weight: 700;
  /* font-size: 20px; */
}

.single-listing .listing-span {
  color: #fff;
  position: absolute;
  top: -25px;
  right: 7px;
  font-size: 16px;
  font-weight: 500;
  min-width: 145px;
  height: 35px;
  background: #002f51;
  border-radius: 8px;
  text-align: center;
  line-height: 35px;
  margin: 5px;
  z-index: 100;
}

.single-listing .list-caption-popular .listing-span {
  color: #fff;
  position: absolute;
  top: -18px;
  right: 7px;
  font-size: 16px;
  font-weight: 500;
  width: 196px;
  height: 35px;
  background: #3193d1;
  border-radius: 12px;
  text-align: center;
  line-height: 35px;
}

.single-listing .list-footer {
  border-top: 1px solid #e1ebf7;
  margin-top: 20px;
  padding-top: 20px;
  font-weight: 700;
  min-height: 150px;
}

.listing-details-area .single-listing .list-footer {
  border-top: 1px solid #e1ebf7;
  margin-top: 20px;
  padding-top: 20px;
  font-weight: 700;
}

.listing-details-area .single-listing .list-footer ul {
  display: flex;
  justify-content: space-between;
}

.single-listing .list-footer ul {
  display: flex;
  justify-content: space-between;
}

.listing-details-area .single-listing .list-footer ul li {
  font-size: 14px;
}

.listing-details-area .single-listing .list-img {
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.single-listing .list-img {
  overflow: hidden;
  border-radius: 12px;
  background-color: #fff;
}

.listing-details-area .single-listing .list-img img {
  width: 100% !important;
  transform: scale(1);
  transition: all 0.6s ease-out 0s;
}

.single-listing .list-img img {
  width: 100%;
  transform: scale(1);
  transition: all 0.6s ease-out 0s;
}

.listing-details-area .single-listing:hover .list-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  /* min-height: 95vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.cta-container {
  width: 100%;
  background: radial-gradient(#2487ce 50%, rgba(36, 135, 206, 0.4) 52%);
  min-height: 20vh;
}

.finance-banner {
  border: 1px solid rgb(203, 214, 220);
  padding: 10px;
  border-radius: 35px;
}

.finance-logo {
  margin-bottom: -80px;
}

.finance-banner h2 {
  color: #6c7888 !important;
  font-size: 22px !important;
  font-weight: 700;
  margin-bottom: 0;
}

.finance-banner span {
  color: #a7a7a7 !important;
  font-size: 12px !important;
  text-align: justify;
}

.immobile-desc {
  color: #0a3d59;
}

.immobile-content-details {
  padding: 18px 5px 0 5px;
  color: #0a3d59;
}

.immobile-content-details h2 {
  color: #062c43 !important;
  font-size: 20px !important;
  font-weight: 700;
  margin-bottom: 0;
}

.immobile-content-company h2 {
  color: #062c43 !important;
  font-size: 24px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding-top: 10px;
  padding-left: 5px;
}

.immobile-content-details p {
  font-size: 14px !important;
}

.imobile-desc {
  font-size: 16px !important;
  letter-spacing: 0.3px;
  word-spacing: 0.5px;
  line-height: 25px;
}

.immobile-content-details-area {
  color: #062c43 !important;
  font-size: 14px !important;
  font-weight: 600;
  margin-bottom: 20px;
}

.immobile-content-more-details {
  color: #062c43 !important;
  font-size: 14px !important;
  font-weight: 600;
  margin-bottom: 20px;
}

.immobile-content-more-details ul {
  padding-left: 20px;
}

.immobile-content-more-details li {
  list-style-type: square;
}

.immobile-content-details-area p {
  margin-bottom: 5px !important;
}

.immobile-content-details-area span {
  color: rgb(64, 64, 64);
  font-weight: 400;
  font-size: 16px;
  padding-left: 5px;
}

.icon-details {
  color: #0c364e !important;
  font-size: 16px !important;
  font-weight: 600;
  /* padding: 30px 10px; */
}

.form-immobile-details {
  border: 2px solid #6c7888;
  padding: 32px;
  border-radius: 20px;
}

.form-immobile-details h2 {
  color: #a7a7a7;
  font-size: 20px !important;
  font-weight: 600;
}

.form-immobile-details p {
  color: #a7a7a7;
}

.form-immobile-details .immobile-price {
  color: #6c7888;
  font-size: 35px;
  font-weight: 900;
  line-height: 1.4rem;
}

.spotlight {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  background: url('https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/background.jpg?size=small')
    top center;
  background-repeat: no-repeat;
  position: relative;
}

.spotlight h2 {
  color: #a7a7a7;
  font-size: 18px;
  text-align: left;
}

.spot-title-container {
  text-align: center;
  position: relative;
}

.spot-title-container:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 0.1px solid orange;
  background: orange;
  width: 100%;
  transform: translateY(-50%);
}

.spot-title-content {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.spot-title {
  padding: 5px 15px;
  background-color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.spot-title .title-service {
  color: #a7a7a7;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.spot-title .content-service {
  color: #5d5e5d;
  text-align: center;
  font-size: 18px;
  line-height: 1.6rem;
  display: block;
}

#hero-campanha {
  width: 100%;
  min-height: 60vh;
  background-color: #f3f3f3;
  /* background: url('../img/campanha/bannerPageCampanha.png') top center; */
  background-size: cover;
  position: relative;
}

#hero-campanha-mobile {
  width: 100%;
  min-height: 60vh;
  background-color: #f3f3f3;
  /* background: url('../img/campanha/campanha-mobile.png') top center; */
  background-size: cover;
  position: relative;
}

#hero:before {
  padding: 0;
}

#hero .container-hero,
#hero-campanha .container-hero {
  /* padding-top: 190px; */
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  #hero .container-hero {
    padding-top: 80px;
  }

  #hero-campanha .container-hero {
    padding-top: 80px;
  }
}

#hero-especial {
  width: 100%;
  min-height: 60vh;
  position: relative;
  /* background: url('../img/beach/hero-beach.jpeg') top center; */
  background-size: cover;
  position: relative;
}

#hero-especial-color {
  width: 100%;
  min-height: 60vh;
  position: relative;
  /* background: url('../img/vinhedos/mapa-cidade.jpeg') top center; */
  background-size: cover;
  position: relative;
}

#hero-especial:before {
  content: '';
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero-especial-color:before {
  content: '';
  background: rgba(58, 0, 16, 0.85);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero-especial .container-hero,
#hero-especial-color .container-hero {
  padding-top: 175px;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  #hero-especial .container-hero,
  #hero-especial-color .container-hero {
    padding-top: 80px;
  }
}

.card-campanha {
  margin-top: 100px;
  width: 90% !important;
  padding: 20px;
  border: 1px solid rgb(203, 214, 220) !important;
}

.card-busca {
  margin-top: 100px;
  width: 100% !important;
  padding: 20px 5px;
  border: 1px solid rgb(203, 214, 220) !important;
}

.form-campanha-desk {
  position: fixed;
  top: 100px;
  right: 25px;
  z-index: 1030;
  width: 350px;
}

.form-campanha-mobile {
  display: none;
}

.campanha-button-wrapper {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: row;
  flex-wrap: wrap;
}

.campanha-button-wrapper .image-buttons-color,
.campanha-button-wrapper .image-buttons-color-face {
  margin-bottom: 15px;
  margin-right: 10px;
}

.imovel-price {
  font-size: 40px;
  font-weight: 700;
}

.imovel-price-condominio {
  font-size: 18px;
}

.imovel-price-condominio span {
  color: #2d85d0;
  font-size: 18px;
  font-weight: 600;
}

.card-transp {
  border: 0 !important;
  border-radius: 8px;
}

@media (max-width: 575px) {
  .imovel-price {
    font-size: 24px;
    font-weight: 700;
  }

  .imovel-price-condominio {
    font-size: 14px;
  }

  .imovel-price-condominio span {
    color: #2d85d0;
    font-size: 14px;
    font-weight: 600;
  }

  .campanha-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .campanha-button-wrapper .image-buttons-color,
  .campanha-button-wrapper .image-buttons-color-face {
    margin-bottom: 15px;
    margin-right: 10px;
  }

  .image-buttons-label-color {
    font-size: 14px !important;
  }

  .main-panel > .content {
    padding: 0 10px 10px !important;
  }

  .card-campanha {
    margin-top: 100px;
    width: 100% !important;
    padding: 2px;
    border: 1px solid rgb(203, 214, 220) !important;
  }

  .card-campanha .title-detail {
    text-align: center;
    margin-top: 20px;
  }

  .card-busca {
    margin-top: 100px;
    width: 100% !important;
    padding: 2px;
    border: 1px solid rgb(203, 214, 220) !important;
  }

  .card-busca .title-detail {
    text-align: center;
    margin-top: 20px;
  }

  .form-campanha-desk {
    display: none;
  }

  .form-campanha-mobile {
    display: inline;
  }

  .form-desk {
    display: none !important;
  }
}

.list-footer {
  border-top: 1px solid rgb(203, 214, 220);
  padding-top: 5px;
  font-weight: 700;
}

#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 72px;
  color: #124265;
  font-family: 'Poppins', sans-serif;
}

#hero h2 {
  color: #fff;
  margin: 10px 0 0 0;
  font-size: 42px;
  font-weight: 700;
  text-align: left;
}

#hero .btn-get-started {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 14px 50px;
  border-radius: 5px;
  transition: 0.5s;

  color: #fff;
  background: #1f7a69;
}

#hero .btn-get-started:hover {
  background: #3194db;
}

#hero .icon-boxes {
  margin-top: 100px;
}

#hero .icon-box,
.icon-box {
  /* padding: 50px 30px; */
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

#hero .icon-box .title,
.icon-box .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#hero .icon-box .title a,
.icon-box .title a {
  color: #124265;
  transition: 0.3s;
}

#hero .icon-box .description,
.icon-box .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

#hero .icon-box .icon,
.icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 36px;
  line-height: 1;
  color: #2487ce;
}

#hero .icon-box:hover,
.icon-box:hover {
  transform: scale(1.08);
}

#hero .icon-box:hover .title a,
.icon-box:hover .title a {
  color: #2487ce;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-height: 800px) {
  #hero {
    height: 650px;
  }
}

@media (max-width: 992px) {
  #hero {
    height: 650px;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 20px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f8fbfe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-bottom: 0;
  color: #fff;
}

.section-title p {
  margin-bottom: 0;
  font-size: 14px;
  color: #919191;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li + li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #2487ce;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: #2487ce;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #2487ce;
}

.about .content .btn-learn-more:hover {
  background: #2487ce;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 60px 0;
}

.counts .count-box {
  width: 100%;
  text-align: center;
}

.counts .count-box span {
  font-size: 48px;
  line-height: 48px;
  display: block;
  font-weight: 700;
  color: #124265;
  margin-left: 80px;
  margin: auto;
}

.counts .count-box p {
  padding: 8px 0 0 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1e6ca6;
}

/*--------------------------------------------------------------
# About Video
--------------------------------------------------------------*/
.about-video .content {
  font-size: 15px;
}

.about-video .content h3 {
  font-weight: 700;
  font-size: 24px;
  color: #124265;
}

.about-video .content ul {
  list-style: none;
  padding: 0;
}

.about-video .content ul li {
  padding-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.about-video .content ul i {
  font-size: 24px;
  color: #2487ce;
  position: absolute;
  left: 0;
  top: -2px;
}

.about-video .content p:last-child {
  margin-bottom: 0;
}

.about-video .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#2487ce 50%, rgba(36, 135, 206, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about-video .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about-video .play-btn::before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(36, 135, 206, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about-video .play-btn:hover::after {
  border-left: 15px solid #2487ce;
  transform: scale(20);
}

.about-video .play-btn:hover::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;
}

.clients img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(100);
}

.clients img:hover {
  filter: none;
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  text-align: center;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #c9e3f5;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 15px 15px;
  padding: 20px;
  background: #f3f9fd;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
}

.testimonials .testimonial-item p::after {
  content: '';
  width: 0;
  height: 0;
  border-top: 20px solid #f3f9fd;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #2487ce;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2487ce;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  padding: 70px 20px 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.05);
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}

.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}

.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: #f5f5f5;
}

.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 22px;
}

.services .icon-box h4 a {
  color: #124265;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
}

.services .iconbox-green i {
  color: #2aa28c;
}

.services .iconbox-green:hover .icon i {
  color: #fff;
}

.services .iconbox-green:hover .icon path {
  fill: #1f7a69;
}

.services .iconbox-blue i {
  color: #fff;
}

.services .iconbox-blue:hover .icon i {
  color: #fff;
}

.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}

.services .iconbox-orange i {
  color: #ffa76e;
}

.services .iconbox-orange:hover .icon i {
  color: #fff;
}

.services .iconbox-orange:hover .icon path {
  fill: #ffa76e;
}

.services .iconbox-pink i {
  color: #e80368;
}

.services .iconbox-pink:hover .icon i {
  color: #fff;
}

.services .iconbox-pink:hover .icon path {
  fill: #e80368;
}

.services .iconbox-yellow i {
  color: #ffbb2c;
}

.services .iconbox-yellow:hover .icon i {
  color: #fff;
}

.services .iconbox-yellow:hover .icon path {
  fill: #ffbb2c;
}

.services .iconbox-red i {
  color: #ff5828;
}

.services .iconbox-red:hover .icon i {
  color: #fff;
}

.services .iconbox-red:hover .icon path {
  fill: #ff5828;
}

.services .iconbox-teal i {
  color: #11dbcf;
}

.services .iconbox-teal:hover .icon i {
  color: #fff;
}

.services .iconbox-teal:hover .icon path {
  fill: #11dbcf;
}

.newsletter {
  background-color: #6c7888;
  padding: 124px 0;
  height: 275px;
  border-top-left-radius: 160px;
  border-top-right-radius: 160px;
}

.newsletter h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.newsletter p {
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.newsletter-social {
  padding-top: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.newsletter-social-items {
  margin: 0 5px;
}

.contact-item {
  display: flex;
}

.contact-item p {
  font-size: 14px;
  font-weight: 700;
  color: #6c7888;
}

.contact-item span {
  font-size: 18px;
  font-weight: 300;
  color: #6c7888;
}

.section-search {
  /* min-height: 70vh; */
  padding: 50px 0;
}

.section-search h2 {
  font-size: 22px;
  font-weight: 400;
  color: #5f5f5f;
}

.search-bar {
  margin: 75px 0 30px 0;
  border-bottom: 2px solid #fcd08f;
  padding: 20px;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/

.cta {
  background-image: linear-gradient(to right, rgba(35, 91, 132, 0.9), #021338);
  background-size: cover;
  padding: 40px 0;
}

.cta h3 {
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 30px;
}

.cta p {
  font-size: 22px;
  color: #fff;
}

.cta .cta-btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #fff;
  color: #1f7a69;
}

.cta-btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #1f7a69;
  color: #1f7a69;
  min-width: 180px;
}

.cta-btn:hover {
  background: #1f7a69;
  color: #fff;
}

.btn-outline {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.btn-outline:hover {
  background: #fff;
  color: #1f7a69;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 35px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #2487ce;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.portfolio .portfolio-wrap::before {
  content: '';
  background: rgba(36, 135, 206, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: '';
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: '';
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #fff;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

.ant-carousel .slick-dots-bottom {
  bottom: 0 !important;
}

.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: 400 !important;
}

.select-antd .ant-select-selector .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: 400 !important;
}

/*************************/
.page-not-found {
  background-image: linear-gradient(
    to top,
    rgba(245, 252, 252, 0.3),
    rgba(31, 122, 105, 0.1)
  );
  /* url(../img/not-found.jpg); */
  /* background-size: cover; */
}

.page-not-found .hero__caption {
  text-align: center;
}

.page-not-found .hero__caption h1 {
  font-size: 78px;
  font-weight: 700;
  margin-bottom: 14px;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-not-found.hero__caption h1 {
    font-size: 60px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-not-found .hero__caption h1 {
    font-size: 50px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .page-not-found .hero__caption h1 {
    font-size: 51px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .page-not-found .hero__caption h1 {
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

.ant-tabs-ink-bar {
  background: #6c7888 !important;
  height: 3px !important;
  width: 80px !important;
}

.whatsapp {
  background-color: #2dce89;
  color: #fff;
  font-size: 20px;
  position: fixed;
  z-index: 999;

  display: inline-block;
  padding: 7px;
  border-radius: 4px;
  transition: 0.5s;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);

  right: 17px;
  bottom: 100px !important;
}

.whatsapp:hover {
  background: #25a76f;
  color: #fff;
}

.personal-banner {
  padding: 50px;
}

.personal-banner-container {
  background-image: url(https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/bg-personal.png?size=small);
  background-size: cover;
  height: 550px;
  border-radius: 65px;
}

.personal-banner-content {
  padding: 30px;
  height: 100%;
}

.personal-banner-container h3 {
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  padding-top: 50px;
}

.calculator-banner {
  padding: 60px 0;
}

.calculator-banner-container {
  background-image: url(https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/bg-calculator.jpg?size=small);
  background-size: cover;
  height: 350px;
  border-radius: 65px;
  padding: 50px;
}

.calculator-banner-container h3 {
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 30px;
}

.calculator-banner-container p {
  font-size: 20px;
  color: #fff;
}

#servicos h3 {
  color: #6c7888;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}


.search-home{
  padding: 0px;
}

.search-home > div{
  padding-inline: 0px;
}

.hero-leads {
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.1),
      rgba(40, 101, 141, 0.6)),
    url(https://locare.s3.sa-east-1.amazonaws.com/sites/alpha/0fcfddc9386372cc6485b84e.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-leads p {
  text-align: left;
  line-height: 1.2em;
}

.hero-leads .hero__caption {
  text-align: center;
}

.hero-leads .hero__caption h1 {
  font-size: 78px;
  font-weight: 700;
  margin-bottom: 14px;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-leads .hero__caption h1 {
    font-size: 60px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-leads .hero__caption h1 {
    font-size: 50px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-leads .hero__caption h1 {
    font-size: 51px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .hero-leads .hero__caption h1 {
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .calculator-banner-container {
    height: 100%;
  }

  .calculator-banner-container h3 {
    font-size: 30px;
  }

  .calculator-banner-container p {
    font-size: 18px;
  }

  .spot-title-content {
    flex-direction: column;
  }

  .personal-banner {
    padding: 0;
  }

  .personal-banner-container {
    background-image: url(https://locare.s3.sa-east-1.amazonaws.com/sites/my-broker/bg-personal.png?size=small);
    background-position: top center;
    background-size: cover;
    height: 100%;
    border-radius: 20px;
  }

  .personal-banner-container h3 {
    text-align: center;
  }

  .newsletter {
    height: 400px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .newsletter-social {
    padding-bottom: 35px;
  }

  .contact-item p {
    font-size: 16px;
  }

  .finance-banner {
    margin-bottom: 35px;
  }

  .finance-logo {
    margin-bottom: 0;
  }

  .finance-logo img {
    width: -webkit-fill-available;
  }
}

.hero-leads-two {
  background-image: linear-gradient(
    to top,
    rgba(245, 252, 252, 0.4),
    rgba(31, 122, 105, 0.65)
  );
  /* url(../img/rosa-lead-b.jpeg); */
  /* background-size: cover; */
}

.hero-leads-two .hero__caption {
  text-align: center;
}

.hero-leads-two .hero__caption h1 {
  font-size: 78px;
  font-weight: 700;
  margin-bottom: 14px;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-leads-two .hero__caption h1 {
    font-size: 60px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-leads-two .hero__caption h1 {
    font-size: 50px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-leads-two .hero__caption h1 {
    font-size: 51px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .hero-leads-two .hero__caption h1 {
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

.modal {
  height: auto !important;
  left: auto !important;
  top: auto !important;
  overflow: visible !important;
}

.hero-leads-tree {
  background-image: linear-gradient(
    to top,
    rgba(245, 252, 252, 0.4),
    rgba(31, 122, 105, 0.65)
  );
  /* url(../img/alugue-rosa.jpeg); */
  /* background-size: cover; */
}

.hero-leads-tree .hero__caption {
  text-align: center;
}

.hero-leads-tree .hero__caption h1 {
  font-size: 78px;
  font-weight: 700;
  margin-bottom: 14px;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-leads-tree .hero__caption h1 {
    font-size: 60px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-leads-tree .hero__caption h1 {
    font-size: 50px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-leads-tree .hero__caption h1 {
    font-size: 51px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .hero-leads-tree .hero__caption h1 {
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

.hero-leads-four {
  background-image: linear-gradient(
    to top,
    rgba(245, 252, 252, 0.4),
    rgba(31, 122, 105, 0.4)
  );
  /* url(../img/hero-rosa-5.jpeg); */
  /* background-size: cover; */
}

.hero-leads-four .hero__caption {
  text-align: center;
}

.hero-leads-four .hero__caption h1 {
  font-size: 78px;
  font-weight: 700;
  margin-bottom: 14px;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-leads-four .hero__caption h1 {
    font-size: 60px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-leads-four .hero__caption h1 {
    font-size: 50px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-leads-four .hero__caption h1 {
    font-size: 51px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .hero-leads-tree .hero__caption h1 {
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

.leads-height {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .leads-height {
    min-height: 100vh;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .leads-height {
    min-height: 100vh;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .leads-height {
    min-height: 100vh;
  }
}

@media (max-width: 575px) {
  .leads-height {
    min-height: 100vh;
  }

  .section-tittle h2 {
    font-size: 30px !important;
    color: #fff !important;
  }

  .text-campaing {
    color: #fff !important;
    font-size: 22px !important;
    font-weight: 500 !important;
  }
}

.section-tittle h2 {
  font-size: 50px;
  display: block;
  color: #1c1930;
  font-weight: 600;
}

.text-campaing {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}

.text-leads {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: 400;
}

/************************/

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  background-color: #f3f3f3;
  min-height: 58vh;
}

/* .portfolio-details .portfolio-details-slider img {
  width: 100%;
} */

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #2487ce;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #2487ce;
}

.portfolio-details .portfolio-info {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 0 30px rgba(18, 66, 101, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  /* padding-bottom: 20px;
  border-bottom: 1px solid #eee; */
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

/* .portfolio-details .portfolio-description {
  padding-top: 30px;
} */

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #124265;
  margin: 0 10px;
  display: inline-block;
}

.team .member .social a:hover {
  color: #1f7a69;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #124265;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
}

.ant-dropdown {
}

.ant-divider-horizontal {
  margin: 12px 0 !important;
}

.dropdown-child {
  box-shadow: 0 3px 6px -4px #808080, 0 6px 16px #00000020,
    0 9px 28px 8px #0000000d;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 320px;
}

.search-bar-filter {
  display: flex;
  justify-content: flex-end;
}

.search-bar-filter div {
  width: 180px;
}

@media (max-width: 575px) {
  .search-bar-filter {
    display: flex;
    flex-direction: column;
  }

  .search-bar-filter div {
    width: 100%;
  }

  .dropdown-child {
    width: 100%;
  }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background: #fefefe;
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
}

.pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #124265;
}

.pricing .box h4 {
  font-size: 42px;
  color: #2487ce;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .box h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing .box ul {
  padding: 0;
  list-style: none;
  color: #124265;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .box ul li {
  padding-bottom: 16px;
}

.pricing .box ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .box .btn-buy {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 4px;
  color: #2487ce;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 2px solid #2487ce;
}

.pricing .box .btn-buy:hover {
  background: #2487ce;
  color: #fff;
}

.pricing .featured {
  background: #2487ce;
}

.pricing .featured h3,
.pricing .featured h4,
.pricing .featured h4 span,
.pricing .featured ul,
.pricing .featured ul .na {
  color: #fff;
}

.pricing .featured .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .featured .btn-buy {
  color: #fff;
  border: 2px solid #fff;
}

.pricing .featured .btn-buy:hover {
  background: #fff;
  color: #2487ce;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li + li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #87c1ea;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #2487ce;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

.figure-destaque {
  border: 8px solid rgb(255, 255, 255);
  border-radius: 20px;
  position: relative;
  max-width: 500px;
  overflow: hidden;
  cursor: pointer;
  background-color: #ffa76e;
}

.figure-destaque img {
  width: 100%;
  height: auto;
  opacity: 0.9;
  margin: -5px 0px;
  transition: all 300ms ease 0s;
}

.awssld__content {
  background-color: transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.awssld__bullets .awssld__bullets--active {
  transform: scale(1.5);
  background: rgba(31, 122, 105, 0.8) !important;
}

.awssld__bullets button {
  padding: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin: 5px;
  border-radius: 50%;
  background: rgba(31, 122, 105, 0.2);
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45),
    background-color 0.175s ease-out;
}

.emp {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
  overflow: hidden;
  height: 350px;
  border: 0;
  box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
  cursor: pointer;
  border-radius: 8px;
}

.emp:hover {
  box-shadow: 0 1px 10px 1px rgb(39 39 39 / 40%);
}

.emp:hover .emp-description {
  -moz-transform: translateY(-0px);
  -o-transform: translateY(-0px);
  -ms-transform: translateY(-0px);
  -webkit-transform: translateY(-0px);
  transform: translateY(-0px);
  opacity: 1;
  visibility: visible;
}

.emp-description-slim {
  background-color: rgba(31, 122, 105, 0.8);
  padding: 15px 25px;
}

.emp-description {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transform: translateY(155px);
  -o-transform: translateY(155px);
  -ms-transform: translateY(155px);
  -webkit-transform: translateY(155px);
  transform: translateY(155px);
  /* background-color: rgba(31, 122, 105, 0.94); */
  background-color: #1f7a69;
  padding: 20px 25px;
  height: 340px;
  margin-top: 210px;
}

.ui.grey.button {
  background-color: #fff !important;
  color: #000;
}

.ui.grey.button:hover {
  background-color: #fff !important;
  color: #000;
}

.ui.green.button {
  background-color: #002f51 !important;
}

i.green.icon {
  color: #1f7a69 !important;
}

@media (max-width: 575px) {
  .emp-description {
    transform: translateY(0);
  }

  .price-emp {
    margin-bottom: 20px;
  }
}

.emp-description .section-title,
.emp-description-slim .section-title {
  font-size: 16px;
  margin-bottom: 0;
  text-align: left;
}

.emp-description h3,
.emp-description p,
.emp-description span,
.emp-description-slim h3,
.emp-description-slim p,
.emp-description-slim span {
  color: #ffffff !important;
  margin-bottom: 0 !important;
}

.section-content .section-title {
  font-size: 14px;
  letter-spacing: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 0;
}

.emp-description .lead {
  font-size: 24px;
  line-height: 32px;
}

.section-content .lead {
  font-size: 22px;

  color: #3b6d8d;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0;
}

.carousel-caption {
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  background-color: #444444;
  padding: 10px;
}

@media (min-width: 768px) {
  .emp-description {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -moz-transform: translateY(334px);
    -o-transform: translateY(334px);
    -ms-transform: translateY(334px);
    -webkit-transform: translateY(334px);
    transform: translateY(210px);
    background-color: rgba(31, 122, 105, 0.96);
    padding: 5px 25px;
    height: 480px;
    margin-top: 10px;
  }

  .section-content .lead {
    letter-spacing: 1px;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
}

.contact .info i {
  font-size: 20px;
  color: #1f7a69;
  float: left;
  width: 44px;
  height: 44px;
  background: #e3f0fa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #fff;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #fff;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #34cbaf;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #2487ce;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type='submit'] {
  background: #2487ce;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type='submit']:hover {
  background: #3194db;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background: #1f7a69;
  min-height: 40px;
  box-shadow: 0px 0 30px rgb(18 66 101 / 8%);
  color: #fff;
}

.breadcrumbs h2 {
  font-size: 34px;
  font-weight: 300;
}

.breadcrumbs ol {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.breadcrumbs ol li {
  color: #fff;
}

.breadcrumbs ol li + li {
  padding: 0 15px;
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

.price-emp {
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

.container-image-buttons {
  left: 80px;
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 1;
}

.title-detail {
  font-size: 28px;
  font-weight: 700;
  display: block;
  line-height: 34px;
}

.ant-result-subtitle {
  font-size: 26px !important;
  font-weight: 700 !important;
  display: block;
  line-height: 34px;
  color: #404040 !important;
}

.link-bairro {
  font-size: 20px;
  font-weight: 700;
  display: block;
  line-height: 34px;
  margin-top: -15px;
  margin-bottom: 20px;
}

.img-detail {
  height: 64px;
  width: 64px;
}

.container-img-detail {
  margin-right: 24px;
}

@media (max-width: 700px) {
  .img-detail {
    height: 34px;
    width: 34px;
  }

  .container-img-detail {
    margin-left: 8px;
    margin-right: 8px;
  }

  #img-detail-wrapper {
    text-align: center;
    justify-content: center;
  }
}

.image-buttons {
  padding: 4px 16px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 32%);
  cursor: pointer;
  display: inline-block;

  background-color: #fff;
  border: 1px solid #fff;

  border-radius: 4px;
}

.image-buttons-color-small {
  padding: 2px 8px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 32%);
  cursor: pointer;
  display: inline-block;

  background-color: #6c7888;
  border: 1px solid #6c7888;
  color: #fff;

  border-radius: 4px;
}

.image-buttons-color {
  padding: 4px 16px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 32%);
  cursor: pointer;
  display: inline-block;

  background-color: #6c7888;
  border: 1px solid #6c7888;
  color: #fff;

  border-radius: 4px;
}

.image-buttons-color:hover {
  background-color: #2fb69e;
  border: 1px solid #2fb69e;
}

.image-buttons-color-face {
  padding: 4px 16px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 32%);
  cursor: pointer;
  display: inline-block;

  background-color: #4867ab;
  border: 1px solid #4867ab;
  color: #fff;

  border-radius: 4px;
}

.image-buttons-color-face:hover {
  background-color: #6f8ac3;
  border: 1px solid #6f8ac3;
}

.image-buttons-label {
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
  margin-top: 4px;
}

.image-buttons-label-color {
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
  /* margin-top: 4px; */
}

.title-especial {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}

.title-especial span {
  font-size: 38px;
  color: #f58734;
}

.text-especial {
  color: #fff;
  font-size: 18px;
}

.container-ficha {
}

.card-ficha {
  background-color: #1f483c;
  padding: 50px;
}

.container-ficha h2 {
  /* display: block;
  font-size: 45px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 0;
  margin: 0 0 -14px -3px; */
}

@media (max-width: 700px) {
  .container-image-buttons {
    display: none;
  }

  .container-collumns {
    display: none !important;
  }

  .slick-next {
    display: none !important;
  }

  .card-horizontal-price {
    margin: 0;
  }

  .title-especial {
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  }
}

.switch-wrap {
  margin-bottom: 10px;
}

#detail-map {
  width: 100%;
  height: 400px;
  background-color: grey;
}

.main-panel > .content {
  padding: 0 30px 30px;
  min-height: 60vh;
}

#wrapper {
  height: 100%;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.MuiButton-contained {
  background-color: #3193d1 !important;
  color: #fff !important;
}

.ant-notification-notice {
  width: 335px !important;
  background-color: #fff !important;
  border-radius: 6px !important;
  padding: 12px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-left: 48px !important;
  color: #6c7888 !important;
  font-weight: 600 !important;
}

.ant-notification-notice-close {
  color: #fff !important;
  top: 10px !important;
}

.place-form {
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 400 !important;
}

.place-form .ant-select-selector .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 400 !important;
}

.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 400 !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 400 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 400 !important;
}

::placeholder {
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 400 !important;
}

.ant-drawer .ant-drawer-content {
  height: max-content !important;
}

.ant-drawer-content-wrapper {
  height: max-content !important;
}

.ant-drawer-wrapper-body button {
  display: none;
}

.ant-drawer-body {
  padding: 15px !important;
}

.card-horizontal-price {
  margin: 0 30px;
}

.card-horizontal {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  overflow: hidden;
  position: relative;
}

.card-horizontal:hover {
  box-shadow: 0px 10px 20px 0px rgb(42 34 123 / 30%);
}

.card-horizontal-img img {
  width: 100%;
  height: 200px;
  position: relative;
}

.list-img {
  width: 100% !important;
  height: 300px !important;
}

.list-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card-horizontal-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

@media screen and (min-width: 720px) {
  .card-horizontal {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .card-horizontal-img img {
    height: 250px;
    width: 250px;
  }

  .list-img {
    width: 100% !important;
    height: 250px !important;
  }

  .list-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1200px) {
  .card-horizontal-img img {
    width: 380px;
    height: 300px;
  }

  .list-img img {
    width: 380px;
    height: 250px;
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: #3193d1;
  font-size: 30px;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: #3193d1;
}

.ant-carousel .slick-prev {
  left: -36px !important;
}

/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content {
  background-color: #f6f9ff;
  padding: 40px;
}

.about h3 {
  font-size: 14px;
  font-weight: 700;
  color: #002f51;
  text-transform: uppercase;
}

.about h2 {
  font-size: 24px;
  font-weight: 700;
  color: #002f51;
}

.about p {
  margin: 15px 0 30px 0;
  line-height: 24px;
}

.about .btn-read-more {
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #002f51;
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.about .btn-read-more span {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.about .btn-read-more i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.about .btn-read-more:hover i {
  transform: translateX(5px);
}

/*--------------------------------------------------------------
# Values
--------------------------------------------------------------*/
.values .box {
  padding: 30px;
  box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
  text-align: center;
  transition: 0.3s;
  height: 100%;
}

.values .box img {
  padding: 30px 50px;
  transition: 0.5s;
  transform: scale(1.1);
}

.values .box h3 {
  font-size: 24px;
  color: #002f51;
  font-weight: 700;
  margin-bottom: 18px;
}

.values .box:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.values .box:hover img {
  transform: scale(1);
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  display: flex;
  align-items: center;
  padding: 30px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.counts .count-box i {
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
  color: #002f51;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #0b198f;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .feature-box {
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
  cursor: pointer;
}

.features .feature-box span {
  font-size: 14px;
  line-height: 22px;
  color: #002f51;
  font-weight: 600;
  margin: 0;
}

.features .feature-box h3 {
  font-size: 18px;
  color: #002f51;
  font-weight: 700;
  margin: 0;
}

.features .feature-box i {
  background: #ecf3ff;
  padding: 4px;
  margin-right: 15px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.features .feature-box:hover i {
  background: #ccdfff;
}

.features .feture-tabs {
  margin-top: 120px;
}

.features .feture-tabs h3 {
  color: #002f51;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .features .feture-tabs h3 {
    font-size: 28px;
  }
}

.features .feture-tabs .nav-pills {
  border-bottom: 1px solid #eee;
}

.features .feture-tabs .nav-link {
  background: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #002f51;
  padding: 12px 0;
  margin-right: 25px;
  margin-bottom: -2px;
  border-radius: 0;
}

.features .feture-tabs .nav-link.active {
  color: #002f51;
  border-bottom: 3px solid #002f51;
}

.features .feture-tabs .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: #002f51;
}

.features .feture-tabs .tab-content i {
  font-size: 24px;
  line-height: 0;
  margin-right: 8px;
  color: #002f51;
}

.features .feature-icons {
  margin: 10px 0;
}

.features .feature-icons h3 {
  color: #002f51;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .features .feature-icons h3 {
    font-size: 28px;
  }
}

.features .feature-icons .content .icon-box {
  display: flex;
}

.features .feature-icons .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #002f51;
}

.features .feature-icons .content .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: #0245bc;
  margin-right: 15px;
}

.features .feature-icons .content .icon-box p {
  font-size: 15px;
  color: #848484;
}

.section-header p {
  margin: 10px 0 0;
  padding: 0;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
  color: #002f51;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .service-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  height: 100%;
  padding: 60px 30px;
  text-align: center;
  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;
}

.services .service-box .icon {
  font-size: 36px;
  padding: 40px 20px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 25px;
  display: inline-block;
  line-height: 0;
  transition: 0.3s;
}

.services .service-box h3 {
  color: #444444;
  font-weight: 700;
}

.services .service-box .read-more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 20px;
}

.services .service-box .read-more i {
  line-height: 0;
  margin-left: 5px;
  font-size: 18px;
}

.services .service-box.blue {
  border-bottom: 3px solid #002f51;
}

.services .service-box.blue .icon {
  color: #2db6fa;
  background: #dbf3fe;
  width: 90px;
}

.services .service-box.blue .read-more {
  color: #2db6fa;
}

.services .service-box.blue:hover {
  background: #002f51;
}

.services .service-box.orange {
  border-bottom: 3px solid #f68c09;
}

.services .service-box.orange .icon {
  color: #f68c09;
  background: #fde3c4;
}

.services .service-box.orange .read-more {
  color: #f68c09;
}

.services .service-box.orange:hover {
  background: #f68c09;
}

.services .service-box.green {
  border-bottom: 3px solid #08da4e;
}

.services .service-box.green .icon {
  color: #08da4e;
  background: #cffddf;
}

.services .service-box.green .read-more {
  color: #08da4e;
}

.services .service-box.green:hover {
  background: #08da4e;
}

.services .service-box.red {
  border-bottom: 3px solid #e9222c;
}

.services .service-box.red .icon {
  color: #e9222c;
  background: #fef7f8;
}

.services .service-box.red .read-more {
  color: #e9222c;
}

.services .service-box.red:hover {
  background: #e9222c;
}

.services .service-box.purple {
  border-bottom: 3px solid #b50edf;
}

.services .service-box.purple .icon {
  color: #b50edf;
  background: #f8e4fd;
}

.services .service-box.purple .read-more {
  color: #b50edf;
}

.services .service-box.purple:hover {
  background: #b50edf;
}

.services .service-box.pink {
  border-bottom: 3px solid #f51f9c;
}

.services .service-box.pink .icon {
  color: #f51f9c;
  background: #feecf7;
}

.services .service-box.pink .read-more {
  color: #f51f9c;
}

.services .service-box.pink:hover {
  background: #f51f9c;
}

.services .service-box:hover h3,
.services .service-box:hover p,
.services .service-box:hover .read-more {
  color: #fff;
}

.services .service-box:hover .icon {
  background: #fff;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 40px 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.pricing .box:hover {
  transform: scale(1.1);
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.pricing h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.pricing .price {
  font-size: 36px;
  color: #444444;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.pricing .price sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .price span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing img {
  padding: 30px 40px;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 25px;
}

.pricing ul li {
  padding-bottom: 10px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-buy {
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  color: #002f51;
  transition: none;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 1px solid #002f51;
}

.pricing .btn-buy:hover {
  background: #002f51;
  color: #fff;
}

.pricing .featured {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #002f51;
  color: #fff;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 15px 15px 20px 0;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  color: #444444;
  text-align: left;
}

.faq .accordion-button:focus {
  box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
  background: none;
  color: #002f51;
  border-bottom: 0;
}

.faq .accordion-body {
  padding: 0 0 25px 0;
  border: 0;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: white;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 10px 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: #444444;
  margin: 0 4px 8px 4px;
  transition: 0.3s;
  border-radius: 50px;
  border: 1px solid #fff;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #002f51;
  border-color: #002f51;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(255, 255, 255, 0.75);
}

.portfolio .portfolio-wrap::before {
  content: '';
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: 1s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: '';
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid rgba(1, 41, 112, 0.2);
  border-left: 3px solid rgba(1, 41, 112, 0.2);
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: '';
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid rgba(1, 41, 112, 0.2);
  border-right: 3px solid rgba(1, 41, 112, 0.2);
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #002f51;
  font-weight: 700;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #002f51;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  background: #002f51;
  margin: 10px 2px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a i {
  font-size: 24px;
  line-height: 0;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  background: #5969f3;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.1);
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #002f51;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #002f51;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  box-shadow: 0px 0 20px rgba(1, 41, 112, 0.1);
  background: #fff;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #002f51;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #002f51;
}

.testimonials .swiper-slide {
  opacity: 0.3;
}

@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }

  .testimonials .swiper-pagination {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding: 60px 0;
}

.team .member {
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .member-img:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  /* background: url(../img/team-shape.svg) no-repeat center bottom; */
  background-size: contain;
  z-index: 1;
}

.team .member .social {
  position: absolute;
  right: -100%;
  top: 30px;
  opacity: 0;
  border-radius: 4px;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2;
}

.team .member .social a {
  transition: color 0.3s;
  color: rgba(1, 41, 112, 0.5);
  margin: 15px 12px;
  display: block;
  line-height: 0;
  text-align: center;
}

.team .member .social a:hover {
  color: rgba(1, 41, 112, 0.8);
}

.team .member .social i {
  font-size: 18px;
}

.team .member .member-info {
  padding: 10px 15px 20px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #002f51;
}

.team .member .member-info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  padding-top: 15px;
  line-height: 26px;
  color: #5e5e5e;
}

.team .member:hover {
  transform: scale(1.08);
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.team .member:hover .social {
  right: 8px;
  opacity: 1;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .clients-slider .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
}

.clients .clients-slider .swiper-slide img:hover {
  opacity: 1;
}

.clients .clients-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #002f51;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #002f51;
}

/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/
.recent-blog-posts .post-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.recent-blog-posts .post-box .post-img {
  overflow: hidden;
  margin: -30px -30px 15px -30px;
  position: relative;
}

.recent-blog-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-box .post-date {
  font-size: 16px;
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
  display: block;
  margin-bottom: 10px;
}

.recent-blog-posts .post-box .post-title {
  font-size: 24px;
  color: #002f51;
  font-weight: 700;
  margin-bottom: 18px;
  position: relative;
  transition: 0.3s;
}

.recent-blog-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
}

.recent-blog-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-blog-posts .post-box:hover .post-title {
  color: #002f51;
}

.recent-blog-posts .post-box:hover .post-img img {
  transform: rotate(6deg) scale(1.2);
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  background: #fafbff;
  padding: 30px;
}

.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #002f51;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #002f51;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: #fafbff;
  padding: 30px;
  height: 100%;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #002f51;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type='submit'] {
  background: #002f51;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type='submit']:hover {
  background: #5969f3;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #002f51;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #002f51;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #4084fd;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #002f51;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #5969f3;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #002f51;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #0d64fd;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #013289;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #002f51;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ',';
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #002f51;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(1, 41, 112, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #002f51;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #002f51;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #013ca3;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #002f51;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #013289;
}

.blog .blog-pagination {
  color: #024ed5;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #002f51;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #002f51;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #002f51;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type='text'] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #002f51;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #5465f2;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #002f51;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #002f51;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #002f51;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #002f51;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #0257ee;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #d7e6ff;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #002f51;
  background: #002f51;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #a5c5fe;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #f6f9ff;
  padding: 0 0 30px 0;
  font-size: 14px;
}

.footer .footer-newsletter {
  padding: 50px 0;
  background: #f6f9ff;
  border-top: 1px solid #e1ecff;
}

.footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  color: #002f51;
}

.footer .footer-newsletter form {
  margin-top: 20px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #e1ecff;
}

.footer .footer-newsletter form input[type='email'] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}

.footer .footer-newsletter form input[type='submit'] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #002f51;
  color: #fff;
  transition: 0.3s;
  border-radius: 4px;
}

.footer .footer-newsletter form input[type='submit']:hover {
  background: #5969f3;
}

.footer .footer-top {
  /* background: white url(../img/footer-bg.png) no-repeat right top;
  background-size: contain; */
  border-top: 1px solid #e1ecff;
  border-bottom: 1px solid #e1ecff;
  padding: 60px 0 30px 0;
}

@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }
}

.footer .footer-top .footer-info {
  margin-bottom: 30px;
}

.footer .footer-top .footer-info .logo {
  line-height: 0;
  margin-bottom: 15px;
}

.footer .footer-top .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-top .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #002f51;
  font-family: 'Poppins', sans-serif;
  margin-top: 3px;
}

.footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
}

.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  color: #002f51;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #002f51;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  color: #013289;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-top .footer-links ul a:hover {
  color: #002f51;
}

.footer .footer-top .footer-contact p {
  line-height: 26px;
}

.footer .copyright {
  text-align: center;
  padding-top: 30px;
  color: #002f51;
}

.footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #002f51;
}

/* Swiperjs Styles */

/* Style custom prev and next buttons */
.swiper-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  color: #fff;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.swiper-button-next,
.swiper-button-prev {
  color: #000;
  padding: 15px;
  border-radius: 999px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 16px;
  width: 50px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  transform: scale(1.1);
}

.swiper-button:hover {
  background-color: #ff9900;
}

.main-orange {
  color: #6c7888 !important;
}

.bg-main-orange {
  background: #6c7888 !important;
  color: #ffff !important;
}

.group-label-normal {
  color: #6c7888 !important;
}

@media (max-width: 575px) {
  .simulator-container {
    display: flex !important;
    padding-inline: 20% !important;
    flex-wrap: wrap !important;
  }
}
